<template>
    <router-link to="/apply" class="header-connection"></router-link>
</template>

<script>
</script>

<style lang="scss" scoped>
    .header-connection{
        width: 100px;
        height: 100px;
        background-image: url("../assets/img/apply_image.png");
        background-size: cover;
        border-radius: 50%;
        background-repeat:no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color:#fff;
        font-weight: 200;
        font-size: 1.125rem;
        position: absolute;
        top:0;
        right:12px;
        display: none;
        z-index: 1000;
        img{
            width: 30px;
            margin-bottom: 0px;
        }
    }
    @media  (min-width: 992px){
        .header-connection{
            display: block;
            width: 142px;
            height: 148px;
            background-size: contain;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: 200;
            font-size: 1.125rem;
            position: absolute;
            top:0;
            right:0;
            border-radius: 0;
            img{
                width: 48px;
                margin-bottom: 6px;
            }
        }
    }
</style>