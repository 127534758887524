import { createApp } from "vue";
import "bootstrap";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap-icons/font/bootstrap-icons.css"
import VueGtag from "vue-gtag";

createApp(App)
    .use(store)
    .use(router)
    .use(VueGtag,{
        config:{
          id:'G-Y4C3PQ11X6'
        }
    }, router)
    .mount("#app");
