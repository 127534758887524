import { createRouter, RouteRecordRaw, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children:[
      {
        path: "",
        name: "index",
        component: () =>
          import("../views/Index.vue"),
      },
      {
        path: "event",
        name: "event",
        component: () =>
          import("../views/pages/event/Event.vue"),
      },
      {
        path: "apply",
        name: "apply",
        component: () =>
          import("../views/pages/apply/Apply.vue"),
      },
      {
        path: "news",
        name: "news",
        component: () =>
          import("../views/pages/news/News.vue"),
      },
      {
        path: "newscontent/:id?",
        name: "newscontent",
        component: () =>
          import("../views/pages/news/NewsContent.vue"),
      },
      {
        path: "consult",
        name: "consult",
        component: () =>
          import("../views/pages/consult/Consult.vue"),
      },
      {
        path: "travel_place",
        name: "travel_place",
        component: () =>
          import("../views/pages/travel-place/TravelPlace.vue"),
      },
      {
        path: "pass_list",
        name: "pass_list",
        component: () =>
          import("../views/pages/pass-list/PassList.vue"),
      },
      {
        path: "tips",
        name: "tips",
        component: () =>
          import("../views/pages/tips/Tips.vue"),
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
