<template>
    <footer class="footer mt-auto">
        <div class="container-xxl font18">
            <div class="row web-infomation">
                <div class="infomation col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 text-center mt-4 d-flex justify-content-center">
                    <a>
                        <img src="../assets/img/footer/footer-logo.png" class="footer-logo-png" alt="">
                    </a>
                </div>
                <div class="footer-about col-10">
                    <div class="row">
                        <ul class="connection col-md-4">
                            <li>諮詢專線：</li>
                            <li class="font16">
                                <a href="tel:+886-3-8906959">
                                    <i class="bi bi-telephone-fill"></i>03-8906959
                                </a>
                                /
                                <a href="tel:+886-3-8906957">
                                    03-8906957
                                </a>
                            </li>
                            <li class="font16">週一至週五 9:30~12:00 / 13:30~18:30</li>
                        </ul>
                        <ul class="connection col-md-5">
                            <li>位置資訊：</li>
                            <li class="font16">
                                <a href="https://g.page/iparkndhu?share" target="_blank">
                                    <i class="bi bi-geo-alt-fill"></i><span></span> 花蓮市華西路123號東華大學創新園區
                                </a>
                            </li>
                            
                        </ul>
                        <ul class="location col-md-2 text-end">
                            <!-- <li>
                                <a>
                                    <img src="../assets/img/footer/footer-personal-info-protect.png" alt="" style="cursor: pointer; width: auto;" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                </a>
                            </li> -->
                            <li class="text-center"
                                style="
                                border: solid;
                                border-radius: 10% / 40%;
                                padding: 0px 5px;
                                width: 170px;
                            ">
                                <span data-bs-toggle="modal" data-bs-target="#staticBackdrop" style="cursor: pointer;width: auto;">
                                    個人資料蒐集聲明
                                </span>
                            </li>
                        </ul>
                    </div>
                    <p></p>
                    <div class="row">
                        <div class="version col-12 pt-3">
                            <div class="box d-flex justify-content-between border-top pt-3">
                                <p class="font16">請使用IE第10版以上、Chrome、FireFox、Edge、Safari瀏覽器瀏覽</p>
                                <p class="font16">本網站為花蓮縣政府版權所有</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
        <!-- Modal -->
        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" style="color: #000000;">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">個人資料保護法告知說明書</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <p>
                                個人資料保護法告知說明書<br>
                                &nbsp;<br>
                                雷門數據服務股份有限公司（下稱本公司）承辦花蓮縣政府「花蓮觀光振興計畫」規劃執行案「110年花蓮縣政府旅行社獎勵計畫」，依據個人資料保護法（以下稱個資法）規定，向台端告知下列事項，請台端詳閱：<br>
                                1.	蒐集之目的：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(1)	「110年花蓮縣政府旅行社獎勵計畫」<br>
                                &nbsp;<br>
                                2.	蒐集之個人資料類別：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(1)	姓名。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(2)	身分證統一編號。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(3)	地址。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(4)	電話。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(5)	電子信箱。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(6)	出生年月日。<br>
                                &nbsp;<br>
                                3.	個人資料利用之期間、地區、對象、方式：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(1)	期間：110年10月8日至111年11月5日為保存之期間。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(2)	對象：參加「110年花蓮縣政府旅行社獎勵計畫」之旅行團成員。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(3)	地區：上述對象所在之地區。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(4)	方式：合於法令規定之利用方式。<br>
                                &nbsp;<br>
                                4.	依據個資法第三條規定，台端就本府保有台端之個人資料得行使之權利及方式：：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;(1)	得向本府行使之權利：<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A.	向本府查詢、請求閱覽或請求製給個人資料複製本。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;B.	向本府請求補充或更正個人資料。<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C.	向本府請求停止蒐集、處理或利用及請求刪除個人資料。<br>
                                &nbsp;<br>
                                5.	行使權利之方式：<br>
                                您可以透過書面（包含電子郵件、傳真、電子文件）或致電服務專線（電話：03-8906959、03-8906957）之方式行使權利。<br>
                            </p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">關閉</button>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer{
    background-color: #D2564C;
    color:#fff;
    padding-top: 32px;
    padding-bottom: 32px;
    a{
        color:#fff;
    }
}
.container-xxl{
    margin-top: 44px;
    .web-infomation{
        position: relative;
        .infomation{
            margin-bottom: 32px;
        }
        .footer-about{
            margin-bottom: 30px;
            .connection, .loaction{
                li{
                    &:first-child{
                        margin-bottom: 24px;
                    }
                }
            }
        }
        .social-media{
            position: absolute;
            right:0;
            top:0;
            width: auto;
        }
    }
}
@media  (max-width: 1199.98px) and (min-width: 768px){
    .web-infomation{
        position: relative;
        .accessibility{
            position: absolute;
            right:0;
            top:0;
        }
    }
}
@media  (max-width: 991.98px){
    .version{
        .box{
            display: block !important;
        }
    }
}
@media  (max-width: 767.98px){
    .container-xxl{
        .web-infomation{
            .infomation{
                a{
                    display: block;
                    margin: 0 !important;
                }
            }
            .footer-about{
                .connection{
                    margin-bottom: 30px;
                }
            }
        }
    }
}
@media  (max-width: 575.98px){
    .container-xxl{
        .web-infomation{
            .social-media{
                position: static;
                display: block;
                margin-bottom: 30px;
            }
        }
    }
}

.footer-logo-png { 
    width: 100%; 
    max-width: 115px; 
} 
</style>