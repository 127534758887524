<template>
    <div class="app-page">
        <router-view />
        <!-- <p id="demo"></p> -->
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
@import "./assets/all.scss";
.app-page{
    position: relative;
}
</style>
