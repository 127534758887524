import request from "../utils/request";

export function news(){
    return request({
        url: "/api/news/list",
        method: "get",
    });
}

export function newsPage(page: Number){
    return request({
        url: `/api/news/list?page=${page}`,  
        method: "get",
    });
}

export function newsDetail(id: Number){
    return request({
        url: `/api/news/detail/${id}`, 
        method: "get",
    })
}

export function news_index(){
    return request({
        url: "/api/news/list_index",
        method: "get",
    });
}