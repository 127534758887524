<template>
  <div class="home">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Index from "./Index.vue";

@Options({
  components: {
    Header,
    Footer,
    Index,
  },
  // data(){
  //   rwdfixed: true;
  // }
})
export default class Home extends Vue {}
</script>

<style lang="scss">
@media (min-width:1200px){
    .home{
        margin-top: 313px;
    }
}
@media (max-width:1199.98px) and (min-width:992px){
    .home{
        margin-top: 330px;
    }
}
</style>