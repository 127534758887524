import request from "../utils/request";

export function get_pass_list_data(data: JSON) {
    return request({
        url: "/api/pass_list",
        method: "post",
        data: data,
    });
}

export function get_pass_amount() {
    return request({
        url: "/api/pass_amount",
        method: "post",
    });
}
