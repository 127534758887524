<template>
    <div class="index-page mobile-top">
        <div class="container-xxl mt-0">
            <img src="../assets/img/index/index_banner.jpg" class="d-block w-100" alt="...">
        </div>
        <div class="news container-xxl mt-50 ps-3 pe-3">
            <div class="section-title h1" id="new">最新消息</div>
            <div class="news-box border rounded mt-20">
                <img :src="newsImage" alt="" class="rounded-top">
                <ul class="font18">
                    <li v-for="item in news" :key="item.id">
                        <router-link :to="{ name: 'newscontent', params: { id : item.id }} " class="text-line1">
                            <span class="me-3">{{ item.release_date }}</span>{{ item.title }}
                        </router-link>
                    </li>
                </ul>
                <div class="more">
                    <router-link to="/news">查看更多<i class="bi bi-chevron-right"></i></router-link>
                </div>
            </div>
        </div>
        <div class="payment-area container-xxl  ps-3 pe-3">
            <div class="row">
                <div class="payment-tools col-xl-7 col-lg-8">
                    <h1 class="section-title">旅行社獎勵計劃</h1>
                    <div class="cards d-flex justify-content-between">
                        <router-link to="/event" class="card mt-20">
                            <div class="event">
                                <span>活動內容</span>
                                <img src="../assets/img/index/plans.png" alt="">
                            </div>
                            <div class="card-body text-center">
                                <div class="more">了解更多</div>
                            </div>
                        </router-link>
                        <router-link to="/apply" class="card mt-20">
                            <div class="apply">
                                <span>注意事項</span>
                                <img src="../assets/img/index/notice.png" alt="">
                            </div>
                            <div class="card-body  text-center">
                                <div class="more">了解更多</div>
                            </div>
                        </router-link>
                        <a class="card mt-20" @click="index_download_document()" style="cursor: pointer;">
                            <div class="document">
                                <span>文件下載</span>
                                <img src="../assets/img/index/document.png" alt="">
                            </div>
                            <div class="card-body  text-center">
                                <div class="more">了解更多</div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-4">
                    <div class="section-title h1">懶人包攻略</div>
                    <router-link to="/tips">
                    <div class="cards d-flex justify-content-between">
                        <a class="card mt-20">
                            <img src="../assets/img/index/tips.png" height="274" alt=""/>
                        </a>
                    </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="cooperation">
            <div class="container-xxl  ps-3 pe-3">
                <div class="section-title h1">景點介紹</div>
                <ul class="nav nav-tabs mt-20" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5 active" id="tab-1" data-bs-toggle="tab" data-bs-target="#tab_type_1" type="button" role="tab" aria-controls="tab_type_1" aria-selected="true">金針花季</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-2" data-bs-toggle="tab" data-bs-target="#tab_type_2" type="button" role="tab" aria-controls="tab_type_2" aria-selected="false">壽豐休閒<br>農業區</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-3" data-bs-toggle="tab" data-bs-target="#tab_type_3" type="button" role="tab" aria-controls="tab_type_3" aria-selected="false">馬太鞍休閒<br>農業區</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-4" data-bs-toggle="tab" data-bs-target="#tab_type_4" type="button" role="tab" aria-controls="tab_type_4" aria-selected="false">舞鶴休閒<br>農業區</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-5" data-bs-toggle="tab" data-bs-target="#tab_type_5" type="button" role="tab" aria-controls="tab_type_5" aria-selected="false">東豐休閒<br>農業區</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-6" data-bs-toggle="tab" data-bs-target="#tab_type_6" type="button" role="tab" aria-controls="tab_type_6" aria-selected="false">羅山休閒<br>農業區</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-7" data-bs-toggle="tab" data-bs-target="#tab_type_7" type="button" role="tab" aria-controls="tab_type_7" aria-selected="false">花蓮縣原住民<br>文化館</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link h5" id="tab-8" data-bs-toggle="tab" data-bs-target="#tab_type_8" type="button" role="tab" aria-controls="tab_type_8" aria-selected="false">東大門<br>夜市</button>
                    </li>
                </ul>
                <div class="tab-content border-start border-bottom border-end rounded-bottom" id="myTabContent">
                    <div class="tab-pane fade show active" id="tab_type_1" role="tabpanel" aria-labelledby="tab-1">
                        <div class="link-box">
                            <a href="https://www.erv-nsa.gov.tw/zh-tw/attractions/detail/53" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel_01.png" alt="">
                                </div>
                            </a>
                            <a href="https://www.erv-nsa.gov.tw/zh-tw/attractions/detail/53" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_2" role="tabpanel" aria-labelledby="tab-2">
                        <div class="link-box">
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/70" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel_03.png" alt="">
                                </div>
                            </a>
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/70" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_3" role="tabpanel" aria-labelledby="tab-3">
                        <div class="link-box">
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/71" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel_04.png" alt="">
                                </div>
                            </a>
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/71" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_4" role="tabpanel" aria-labelledby="tab-4">
                        <div class="link-box">
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/68" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel04.png" alt="">
                                </div>
                            </a>
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/68" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_5" role="tabpanel" aria-labelledby="tab-5">
                        <div class="link-box">
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/69" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel05.png" alt="">
                                </div>
                            </a>
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/69" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_6" role="tabpanel" aria-labelledby="tab-6">
                        <div class="link-box">
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/6332" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel06.png" alt="">
                                </div>
                            </a>
                            <a href="https://ezgo.coa.gov.tw/zh-TW/Front/Agri/Detail/6332" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_7" role="tabpanel" aria-labelledby="tab-6">
                        <div class="link-box">
                            <a href="https://www.facebook.com/HualienCountyIndigenousPeoplesCulturalCenter/" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel_07.jpg" alt="">
                                </div>
                            </a>
                            <a href="https://www.facebook.com/HualienCountyIndigenousPeoplesCulturalCenter/" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="tab_type_8" role="tabpanel" aria-labelledby="tab-6">
                        <div class="link-box">
                            <a href="https://www.facebook.com/hualien.night.market/" target="_blank" rel="noopener">
                                <div class="text-center">
                                    <img src="../assets/img/travel01.png" alt="">
                                </div>
                            </a>
                            <a href="https://www.facebook.com/hualien.night.market/" target="_blank" rel="noopener">
                                <div class="card-body text-center">
                                    <div class="more" style="color: #707070">
                                        了解更多
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Connection from '../components/Connection-fix-btn.vue';
import { news_index } from '../api/news';
import { download_document } from '../api/download';
import FileSaver from 'file-saver'

export default {
    components:{
        Connection
    },
    data() {
        return {
            news: [],
            newsImage: "",
        }
    },
    methods:{
        getNews(){
            news_index().then( response=>{
                if (response.data.success === true) {
                    this.news = response.data.data;
                    if (response.data.data.length !== 0) {
                        this.newsImage = response.data.data[0].main_image;
                    } else {
                        this.newsImage = "";
                    }
                } else {
                    this.news = [];
                    this.newsImage = "";
                }
            }, error => {
                // console.log(error)
            })
        },
        index_download_document() {
            download_document().then(response => {
                FileSaver.saveAs(new Blob([response.data]), "110年花蓮縣政府旅行社獎勵計畫-申請文件.docx")
            }, error => {
                // console.log(error)
            })
        }
    },
    created(){
        window.scrollTo({top: 0, behavior: "smooth"});
        this.getNews();
    },
}
</script>

<style lang="scss" scoped>
.index-page{
    overflow: auto;
    position: relative;
}

.container-xxl{
    &:first-child{
        margin-top: 0;
    }
}
.banner{
    position: relative;
    &:before{
        content:'';
        position: absolute;
        width: 100%;
        height: 30px;
        background-color: transparent;
        z-index: 10;
        -webkit-box-shadow: inset 0px 17px 9px -13px rgba(0, 0, 0, 0.101); 
        box-shadow: inset 0px 17px 9px -13px rgba(0, 0, 0, 0.101);
    }
}
.news{
    margin-bottom: 50px;
    img{
        width: 100% !important;
    }
    ul{
        padding-left: 40px;
        padding-top: 43px;
        padding-right: 40px;
        padding-bottom: 20px;
        position: relative;
        li{
            padding-bottom: 10px;
            border-bottom:1px dashed #a1a1a1;
            margin-top: 20px;
            &:first-child{
                border:1px solid #a1a1a1;
                border-radius: 0.25em;
                margin: 0;
                padding: 24px 20px;
                position: absolute;
                width: 93.5%;
                left: 50%;
                transform: translate(-50%,0);
                top:-43px;
                background-color: #fff;
                -webkit-box-shadow: 0px 12px 9px -7px rgba(0,0,0,0.18); 
                box-shadow: 0px 12px 9px -7px rgba(0,0,0,0.18);
            }
            a{
                color:#4d4d4d;
            }
        }
    }
    .more{
        padding-right: 40px;
        text-align: right;
        margin-bottom: 20px;
        a{
            color:#707070;
            font-weight: 200;
        }
    }
}
.payment-area{
    margin-top: 100px;
    .row{
        .payment-tools{
            .cards{
                .card{
                    width: 30%;
                    .event, .apply, .document{
                        width: 100%;
                        height: 213px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        align-items: center;
                        background-position: top;
                        border-radius: 0.15em 0.15em 0 0;
                        img{
                            display: block;
                            width: auto;
                        }
                        span{
                            color:#fff;
                            // text-shadow: 1px 1px 0px rgb(147, 146, 146);
                        }
                    }
                    .event{
                        background-image: url('../assets/img/index/bg_01.png');
                        // span{
                            // text-shadow: 1px 1px 0px #7e967f;
                            // color:#616E7D !important;
                        // }
                    }
                    .apply{
                        background-image: url('../assets/img/index/bg_02.png');
                        // span{
                        //     text-shadow: 1px 1px 0px #558a82;
                        // }
                    }
                    .document{
                        background-image: url('../assets/img/index/bg_03.png');
                        // span{
                        //     text-shadow: 1px 1px 0px #c28a84;
                        // }
                    }
                    .card-body{
                        .more{
                            display: block;
                            color:#707070;
                            font-weight: 200;
                        }
                    }
                    &:hover{
                        transition: .5s;
                        -webkit-box-shadow: 0px 12px 14px 2px rgba(0,0,0,0.17); 
                        box-shadow: 0px 12px 14px 2px rgba(0,0,0,0.17);
                    }
                }
            }
        }
    }
}
.cooperation{
    margin-top: 100px;
    margin-bottom: 100px;
    .nav-tabs{
        align-items: flex-end;
        .nav-item{
            .nav-link{
                color: #fff;
                font-weight: 200;
                margin-right: 12px;
            }
            .active{
                padding-top: 30px;
                padding-bottom: 30px;
            }
            &:first-child{
                .nav-link{
                    background-color: #FCCF00;
                    color: #3E3A39;
                }
            }
            &:nth-child(2){
                .nav-link{
                    background-color: #E8546B;
                }
            }
            &:nth-child(3){
                .nav-link{
                    background-color: #AACC03;
                }
            }
            &:nth-child(4){
                .nav-link{
                    background-color: #5DC1CF;
                }
            }
            &:nth-child(5){
                .nav-link{
                    background-color: #CA5FCD;
                }
            }
            &:nth-child(6){
                .nav-link{
                    background-color: #AC8F55;
                }
            }
            &:nth-child(7){
                .nav-link{
                    background-color: #489BE1;
                }
            }
            &:last-child{
                .nav-link{
                    background-color: #C25015;
                }
                margin-right: 0;
            }
        }
    }
    .tab-content{
        padding: 40px;
        padding-bottom: 16px;
        .tab-pane{
            .link-box{
                .item{
                    display: inline-block;
                    width: 20%;
                    padding: 0 12px;
                    margin-bottom: 24px;
                    img{
                        width: 100%;
                        display: block;
                    }
                }
            }
            
        }
    }
    img{
        display: block;
        width: 100%;
    }
}
@media  (max-width: 1114px){
    .cooperation{
        .nav-tabs{
            width: 100%;
            align-items: center;
            display: flex;
            .nav-item{
                width: 33.333333%;
                // margin-right: 2%;
                margin-bottom: 12px;
                padding-left: 12px;
                padding-right: 12px;
                button{
                    width: 100%;
                    font-size: 1.5rem;
                    border-radius: 0.25rem;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                .active{
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }
        }
        .tab-content{
            padding: 0;
            padding-bottom: 0;
            .tab-pane{
                .link-box{
                    padding-top: 12px;
                    .item{
                        width: 25%;
                        padding: 0 12px;
                        margin-bottom: 12px;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
}
@media  (max-width: 991.98px){
    .payment-area{
        .row{
            .video{
                margin-top: 100px;
                iframe{
                    height: 420px !important;
                }
            }
        }
    }
    .cooperation{
        .nav-tabs{
            // width: 100%;
            // align-items: center;
            .nav-item{
                // width: 29.333333%;
                // margin-left: 2%;
                // margin-right: 2%;
                // margin-bottom: 12px;
                // margin-right: 8px;
                button{
                    width: 100%;
                    padding-left: 6px;
                    padding-right: 6px;
                    font-size: 1.5rem;
                    // border-radius: 0.25rem;
                    // padding-top: 12px;
                    // padding-bottom: 12px;
                }
                // .active{
                //     padding-top: 12px;
                //     padding-bottom: 12px;
                // }
            }
        }
        .tab-content{
            padding: 0;
            padding-bottom: 0;
            .tab-pane{
                .link-box{
                    padding-top: 12px;
                    .item{
                        width: 25%;
                        padding: 0 12px;
                        margin-bottom: 12px;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
    img{
        display: block;
        width: 100%;
    }
}
@media  (max-width: 991.98px) and (min-width:768px){
    .index-page{
        margin-top: 243px;
    }
    // .cooperation{
    //     .nav-tabs{
    //         .nav-item{
    //             margin-right: 12px;
    //         }
    //     }
    // }
    .cooperation{
        .nav-tabs{
            width: 100%;
            align-items: center;
            display: flex;
            .nav-item{
                width: 33.333333%;
                // margin-right: 2%;
                margin-bottom: 12px;
                padding-left: 12px;
                padding-right: 12px;
                button{
                    width: 100%;
                    font-size: 1.5rem;
                    border-radius: 0.25rem;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                .active{
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }
        }
        .tab-content{
            padding: 0;
            padding-bottom: 0;
            .tab-pane{
                .link-box{
                    padding-top: 12px;
                    .item{
                        width: 25%;
                        padding: 0 12px;
                        margin-bottom: 12px;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
}
@media  (max-width: 767.98px) and (min-width:576px){
    .index-page{
        margin-top: 215px;
    }
    .cooperation{
        .nav-tabs{
            width: 100%;
            align-items: center;
            display: flex;
            .nav-item{
                width: 33.333333%;
                // margin-right: 2%;
                margin-bottom: 12px;
                padding-left: 12px;
                padding-right: 12px;
                button{
                    width: 100%;
                    font-size: 1.5rem;
                    border-radius: 0.25rem;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                .active{
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }
        }
        .tab-content{
            padding: 0;
            padding-bottom: 0;
            .tab-pane{
                .link-box{
                    padding-top: 12px;
                    .item{
                        width: 25%;
                        padding: 0 12px;
                        margin-bottom: 12px;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
    img{
        display: block;
        width: 100%;
    }
}
@media  (max-width: 575.98px){
    .index-page{
        margin-top: 175px;
    }
}

@media  (max-width: 575.98px){
    .news{
        ul{
            padding-left: 16px;
            padding-right: 16px;
            li{
                font-size: 1rem;
                span{
                    font-size: 0.875rem;
                    display: block;
                    line-height: 12px;
                }
                &:first-child{
                    padding: 6px 12px;
                    top:-20px;
                }
            }
        }
        .more{
            padding-right: 16px;
        }
    }
    .payment-area{
        margin-top: 50px;
        .row{
            .payment-tools{
                .cards{
                    display: block !important;
                    .card{
                        width: 100%;
                    }
                }
            }
            .video{
                margin-top: 50px;
            }
        }
    }
    .cooperation{
        margin-top: 50px;
        .nav-tabs{
            width: 100%;
            align-items: center;
            .nav-item{
                width: 45%;
                margin-left: 2%;
                margin-right: 2%;
                margin-bottom: 12px;
                button{
                    width: 100%;
                    padding-left: 6px;
                    padding-right: 6px;
                    font-size: 1rem;
                    border-radius: 0.25rem;
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
                .active{
                    padding-top: 12px;
                    padding-bottom: 12px;
                }
            }
        }
        .tab-content{
            padding: 0;
            padding-bottom: 0;
            .tab-pane{
                .link-box{
                    padding-top: 12px;
                    .item{
                        width: 50%;
                        padding: 0 12px;
                        margin-bottom: 12px;
                        img{
                            width: 100%;
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
    .contact{
        margin-top: 50px;
    }
    img{
        display: block;
        width: 100%;
    }
}

@media (max-width: 1199.98px) and (min-width:992px){
    .payment-area{
        .row{
            .payment-tools{
                .cards{
                    .card{
                        .payment01, .payment02, .payment03{
                            img{
                                height: 128px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>