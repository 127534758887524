import axios from "axios";
import store from "../store";
import { getToken } from "./auth";

// create an axios instance
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  // baseURL: "http://localhost:8000", // 前後端不同port使用
  timeout: 900000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // TODO 待與後端做登入驗證處理
    // if (store.getters.token) {
    //     config.headers['X-App-Token'] = getToken()
    // }
    return config;
  },
  (reason) => {
    // Do something with request error
    // console.error(reason) // for debug
    Promise.reject(reason);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => response,
  (reason) => {
    console.error(reason); // for debug
    if (reason.response.status == 419) {
      store.dispatch("FedLogOut").then(() => {
        location.reload();
      });
    }
    return Promise.reject(reason);
  }
);

export default service;
