<template>
    <div class="header shadow-sm">
        <div class="container-xxl">
            <nav class="navbar navbar-expand-lg navbar-light" aria-label="Main navigation">
                <div class="container-fluid p-0">
                    <a class="navbar-brand" href="#">
                        <img src="../assets/img/logo.png" alt="">
                    </a>
                    <button class="navbar-toggler p-0 border-0" @click="openCanvas" type="button" id="navbarSideCollapse" aria-label="Toggle navigation">
                        <i class="bi bi-list"></i>
                    </button>
                    <Connection />
                    <div class="row col-xl-12 text-center mb-3 announcement">
                        <div class="col-4 mb-3">
                            <p>{{ datetime_format(new Date()) }} 止可申請金額</p>
                        </div>
                        <div class="col-4 mb-3">
                            <p>剩餘金額 {{ balance }} 元</p>
                        </div>
                        <div class="col-4 mb-3">
                            <p>以每間九萬元上限計約餘 {{ store_count }} 間名額</p>
                        </div>
                    </div>

                    <div class="navbar-collapse offcanvas-collapse navbar-collapse-center" id="navbarsExampleDefault">
                        <ul class="navbar-nav d-block navbar-center">
                            <li class="nav-item">
                                <router-link to="/event" class="nav-link" @click="openCanvas">計畫內容</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/news" class="nav-link" @click="openCanvas">最新消息</router-link>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="nav_bar_download_document()" style="cursor: pointer;">
                                    文件下載
                                </a>
                            </li>
                            <li class="nav-item">
                                <router-link to="/apply" class="nav-link" @click="openCanvas">登錄申請獎勵</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/pass_list" class="nav-link" @click="openCanvas">通過名單公告</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/tips" class="nav-link" @click="openCanvas">獎勵補助攻略</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/travel_place" class="nav-link" @click="openCanvas">景點介紹</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/consult" class="nav-link" @click="openCanvas">問題諮詢</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import Connection from './Connection-fix-btn.vue';
import { download_document } from '../api/download';
import FileSaver from 'file-saver'
import moment from 'moment'
import { get_pass_amount } from '../api/pass_list'

export default {
    components:{
        Connection
    },
    data() {
        return {
            balance: null,
            store_count: null,
        }
    },
    methods: {
        openCanvas(){
            document.querySelector('.offcanvas-collapse').classList.toggle('open');
        },
        nav_bar_download_document() {
            download_document().then(response => {
                FileSaver.saveAs(new Blob([response.data]), "110年花蓮縣政府旅行社獎勵計畫-申請文件.docx")
            }, error => {
                // console.log(error)
            })
        },
        datetime_format(datetime) {
            let year = Number(moment(datetime).format('YYYY')) - 1911
            return year + moment(datetime).format('/MM/DD HH:mm')
        }
    },
    created() {
        get_pass_amount().then(response => {
            this.balance = (response.data.data.balance).toLocaleString();
            this.store_count = response.data.data.store_count;
        }, error => {
            this.balance = null;
            this.store_count = null;
        })
    }
}
</script>

<style lang="scss" scoped>  
html, body{
    overflow-x: hidden; 
}
    .header{
        position: relative;
        z-index: 1000;
        background-color: #fff;
        &:after{
            content:'';
            position: absolute;
            width: 100%;
            height: 50px;
            background-color: #D2564C;
            z-index: -1;
            bottom:0px;
            left: 0;
        }
    }
    .container-fluid{
        flex-direction: column;
        align-items: flex-start;
    }
    .navbar{
        padding-bottom: 0;
    }
    .navbar-brand{
        // margin-top: 30px;
        margin-bottom: 30px;
        img{
            width: 636px;
        }
    }
    .navbar-toggler{
        i{
            font-size: 2rem;
            color:#D2564C;
        }
        &:focus{
            color:#D2564C;
        }
    }
    .navbar-collapse{
        width: 100%;
    }
    .nav-scroller {
        position: relative;
        z-index: 2;
        height: 2.75rem;
        overflow-y: hidden;
    }
    .nav-scroller .nav {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 1rem;
        margin-top: -1px;
        overflow-x: auto;
        color: rgba(255, 255, 255, .75);
        text-align: center;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
    }
    .navbar-expand-lg{
        .navbar-nav{
            justify-content: flex-start;
            width: 100%;
            .nav-item{  //li
                display: inline-block;
                padding: 4px 20px;
                position: relative;
                .dropdown-menu{
                    background-color: #3c6cce;
                    margin-top: 0px;
                    border-radius: 0 0 0.25rem 0.25rem;
                    border-top:0;
                    left: 0;
                    .dropdown-item{
                        color:#fff;
                        font-weight: 200;
                        &:hover{
                            background-color: #729df2;
                        }
                    }
                }
                &:before{
                    content:'';
                    position: absolute;
                    width: 1px;
                    height: 28px;
                    right:0px;
                    top:50%;
                    transform: translate(0,-50%);
                    background-color: #fff;
                }
                &:last-child{
                    &:before{
                        display: none;
                    }
                }
            }
            .nav-link {  
                padding: 0;
                font-size: 1.125rem;
                color: #fff;
                line-height: 42px;
            }
        }
    } 
    .nav-link:hover {
        color: #007bff;
    }
    .active {
        font-weight: 500;
        color: #343a40;
    }
@media (min-width:992px){
    .navbar-expand-lg{
        .navbar-nav{
            .nav-item{  
                &:hover{
                    background-color: #fff;
                    .nav-link{
                        color:#D2564C;
                    }
                    .dropdown-menu{
                        display: block;
                        margin-top: 3px;
                    }
                }
            }
        }
    } 
}
@media (max-width:1199.98px) and (min-width:992px){
    .navbar-expand-lg{
        .navbar-nav{
            .nav-item{  
                padding-left: 1%;
                padding-right: 1%;
            }
        }
    } 
    .navbar-brand{
        img{
            width: 790px;
        }
    }
}
@media (min-width:992px){
    .header{
        position: fixed;
        width: 100%;
        top:0;
        left: 0;
    }
    .navbar-expand-lg{
        .navbar-nav{
            .nav-item{  
                &:hover{
                    background-color: #fff;
                    .nav-link{
                        color:#D2564C;
                    }
                    .dropdown-menu{
                        display: block;
                        margin-top: 3px;
                    }
                }
            }
        }
    } 
}
@media  (max-width: 991.98px) and(min-width:768px){
    .navbar-brand{
        margin-bottom: 0px;
        margin-top: 0;
        width: 520px;
        img{
            width: 100%;
        }
    }
    .offcanvas-collapse {
        top: 243px; /* Height of navbar */
    }
}
@media  (max-width: 767.98px) and(min-width:576px){
    .navbar-brand{
        margin-bottom: 0px;
        width: 420px;
        img{
            width: 100%;
        }
    }
    .offcanvas-collapse {
        top: 217px; /* Height of navbar */
    }
}
@media  (max-width: 575.98px){
    .navbar-brand{
        margin-bottom: 0px;
        width: 240px;
        img{
            width: 100%;
        }
    }
    .offcanvas-collapse {
        top: 173px; /* Height of navbar */
    }
}
@media  (max-width: 991.98px){
    .header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        &:after{
            display: none;
        }
    }
    .container-fluid{
        flex-direction: row;
        align-items: center;
    }
    .navbar{
        padding-bottom: 8px;
    }
    .navbar-brand{
        margin-top: 0px;
    }
    .navbar-expand-lg{
        .navbar-nav{
            .nav-item{  //li
                display: block;
                padding: 0 12px;
                margin: 24px 0;
                position: relative;
                .dropdown-menu{
                    border:0;
                }
                &:hover{
                    background-color: transparent;
                    .nav-link{
                        color:#fff;
                    }
                }
                &:before{
                    display: none;
                }
            }
        }
    } 
    .offcanvas-collapse {
        position: fixed;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: #D2564C;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    }
    .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-100%);
    }
}

.navbar-center {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar-collapse-center {
  text-align: center;
}

.announcement {
    color: #595757;
    font-size: 1.5rem;
}
@media (max-width:1199.98px) and (min-width:992px){
    .announcement {
        font-size: 1.5rem;
    }
}
@media  (max-width: 991.98px) and(min-width:768px){
    .announcement {
        font-size: 1.5rem;
    }
}
@media  (max-width: 767.98px) and(min-width:576px){
    .announcement {
        font-size: 1rem;
    }
}
@media  (max-width: 575.98px){
    .announcement {
        font-size: 1rem;
    }
}
</style>