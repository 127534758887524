
import { Options, Vue } from "vue-class-component";
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import Index from "./Index.vue";

@Options({
  components: {
    Header,
    Footer,
    Index,
  },
  // data(){
  //   rwdfixed: true;
  // }
})
export default class Home extends Vue {}
